import Storage from "@/commons/utils/storage.js"

// import Vue from "vue";
const mainRouter = [
  // 商家管理登录
  {
    path: '/',
    component: () => import('../views/base/main/login/Login.vue')
  },
  {
    path: '/index',
    component: () => import('../views/base/main/home/Home.vue'),
    children: [
      {
        path: '/',
        name: 'desk',
        redirect: '/base/main/desk/desk'
      }, {
        path: '/base/main/desk/desk',
        name: 'desk',
        component: () => import('../views/base/main/desk/desk.vue')
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('../views/aisy/orderManagement/order.vue')
      },
      {
        path: '/billing',
        name: 'billing',
        component: () => import('../views/aisy/billingManagement/billing.vue')
      },
      {
        path: '/commissioSharing',
        name: 'commissioSharing',
        component: () => import('../views/aisy/commissioSharing/commissio.vue')
      },
      {
        path: '/auditing',
        name: 'auditing',
        component: () => import('../views/aisy/auditing/auditing.vue')
      },
      {
        path: '/miniProgram',
        name: 'miniProgram',
        component: () => import('../views/aisy/miniProgram/miniProgram.vue')
      },
    ]
  },

  // 管理登录
  {
    path: '/merchantLogin',
    component: () => import('../views/base/main/merchantLogin/Login.vue')
  },
  {
    path: '/',
    component: () => import('../views/base/main/merchantHome/Home.vue'),
    children: [
      {
        path: '/merchantDesk',
        name: 'merchantDesk',
        redirect: '/base/main/merchantDesk/desk'
      }, {
        path: '/base/main/merchantDesk/desk',
        name: 'merchantDesk',
        component: () => import('../views/base/main/merchantDesk/desk.vue')
      },
      // 商家管理列表
      {
        path: '/merchantGl',
        name: 'merchantGl',
        component: () => import('../views/merchant/merchantGl/merchantGl.vue')
      },
      // 商家管理详情
      {
        path: '/merchantInfo',
        name: 'merchantInfo',
        component: () => import('../views/merchant/merchantInfo/merchantInfo.vue')
      },
      // 线下订单列表
      {
        path: '/merchantOrder',
        name: 'merchantOrder',
        component: () => import('../views/merchant/merchantOrder/merchantOrder.vue')
      },
      // 线上订单列表
      {
        path: '/onlineOrders',
        name: 'onlineOrders',
        component: () => import('../views/merchant/onlineOrders/onlineOrders.vue')
      },
      // 线下订单详情
      {
        path: '/merchantOrderInfo',
        name: 'merchantOrderInfo',
        component: () => import('../views/merchant/merchantOrderInfo/orderInfo.vue')

      },
      // 线上订单详情
      {
        path: '/orderInfo',
        name: 'orderInfo',
        component: () => import('../views/merchant/orderInfo/orderInfo.vue')
      },
      // 换脸模板管理
      {
        path: '/faceChanging',
        name: 'faceChanging',
        component: () => import('../views/merchant/faceChanging/faceChanging.vue')
      },
      // 模型模版管理
      {
        path: '/modelTemplate',
        name: 'modelTemplate',
        component: () => import('../views/merchant/modelTemplate/modelTemplate.vue')
      },
      {
        path: '/modelTemplateInfo',
        name: 'modelTemplateInfo',
        component: () => import('../views/merchant/modelTemplateInfo/modelTemplateInfo.vue')
      },
      {
        path: '/skuManagement',
        name: 'skuManagement',
        component: () => import('../views/merchant/skuManagement/skuManagement.vue')
      },
      // 无人设备风格管理
      {
        path: '/unmannedTypeManagement',
        name: 'unmannedTypeManagement',
        component: () => import('@/views/merchant/unmannedTypeManagement/unmannedTypeManagement.vue')
      },
      // 无人设备图形模板管理
      {
        path: '/unmannedImageTemplate',
        name: 'unmannedImageTemplate',
        component: () => import('@/views/merchant/unmannedImageTemplate/unmannedImageTemplate.vue')
      },
      // 无人设备模型模板管理
      {
        path: '/unmannedModelTemplate',
        name: 'unmannedModelTemplate',
        component: () => import('@/views/merchant/unmannedModelTemplate/unmannedModelTemplate.vue')
      },
      // 无人机订单
      {
        path: '/photoOrder',
        name: 'photoOrder',
        component: () => import('../views/merchant/photoOrderManag/photoOrder.vue')
      },
    ]
  }
]
let menu = Storage.get("menu");
if (menu) {
  menu = Storage.formatTreeData(menu, '0').children;
  mainRouter.forEach((val, index) => {
    if (val.path == "/index") {
      let tmp = [];
      Storage.getDataRouter(menu, tmp);
      tmp = val.children.concat(tmp);
      val.tmp = val.children;
      val.children = tmp;
    }
  });
}
export default mainRouter
