/**
 * @author zxm
 * @date 2020/11/13
 * @Description: 校验公共方法
 */
// 账号
let codeReg = /^(?![0-9]*$)(?![a-zA-Z]*$)[a-zA-Z0-9]{6,20}$/

//用户名格式 //  /(^[A-Za-z]+$) | (^[A-Za-z0-9]+$)/;
let userNameReg =  /^[A-Za-z0-9]+$/;

// 电话
let phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/

// 必须为数字
let numberReg = /^\d+$|^\d+[.]?\d+$/

// 密码
let passwordReg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){6,20}$/

// 联系人
let contactsReg = /^[\u0391-\uFFE5A-Za-z]+$/

let regId = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/

let emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/

//数字字母下划线
let numberStringT=/^[0-9a-zA-Z_]{0,10}$/

let numberStringB=/^[0-9a-zA-Z_]{0,20}$/
//数字字母组合
let numberString = /^[A-Za-z0-9]{0,10}$/
//车牌号校验
let carNum = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/

let FormValidate = (function () {
  function FormValidate () {}
  // From表单验证规则  可用于公用的校验部分
  FormValidate.Form = function () {
    return {
      // 账号的验证规则
      validateCode (rule, value, callback) {
        // if (!value) {
        //   return callback(new Error('请输入账号'))
        // }
        if (!codeReg.test(value)) {
          callback(new Error('账号必须为6-20位字母和数字组合'))
        } else {
          callback()
        }
      },
      validateZero(rule, value, callback) {
          if (value !== '') {
          if (value <=0) {
            callback(new Error('数值必须大于0'));
          }else{
            callback()
          }
        } else {
          callback()
        }
      },
      validateChaobiao(rule, value, callback) {
        if (value !== '') {
        if (value <=0) {  
            callback(new Error('请输入储水量'));
        }else{
          callback()
        }
      } else {
        callback()
      }
    },
      // 只能数字的验证
      validateNumber (rule, value, callback) {
        if (value !== '') {
          if (!numberReg.test(value)) {
            callback(new Error('请输入数字'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      },
      validateNumberString (rule, value, callback) {
        if (value !== '') {
          if (!numberStringT.test(value)) {
            callback(new Error('包含数字字母下划线'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      },
      validateNumberStr (rule, value, callback) {
        if (value !== '') {
          if (!numberString.test(value)) {
            callback(new Error('请输入10位以内数字字母组合字符串'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      },
      validateBaseCode (rule, value, callback) {
        if (value !== '') {
          if (!numberStringB.test(value)) {
            callback(new Error('包含数字字母下划线'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      },

      // 密码的验证
      validatePsdReg (rule, value, callback) {
        // if (!value) {
        //   return callback(new Error('请输入密码'))
        // }
        if (!passwordReg.test(value)) {
          callback(new Error('请输入6-20位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种'))
        } else {
          callback()
        }
      },

      validateUserName (rule,value,callback) {
        if (!userNameReg.test(value)) {
          callback(new Error('联系人不可输入特殊字符'))
        } else {
          callback()
        }

      },

      // 联系人
      validateContacts (rule, value, callback) {
        // if (!value) {
        //   return callback(new Error('请输入联系人'))
        // }
        if (!contactsReg.test(value)) {
          callback(new Error('联系人不可输入特殊字符'))
        } else {
          callback()
        }
      },

      // 邮箱的验证规则
      validateEmail (rule, value, callback) {
        // if (value !== '') {
          if (!emailReg.test(value)) {
            callback(new Error('邮箱格式不正确'))
          } else {
            callback()
          }
        // } else {
        //   callback()
        // }
      },
      // 电话号码的验证
      validatePhone (rule, value, callback) {
        // if (!value) {
        //   return callback(new Error('请输入手机号码'))
        // }
        if (!phoneReg.test(value)) {
          callback(new Error('手机格式不正确'))
        } else {
          callback()
        }
      },
      validateCarNum (rule, value, callback) {
        if (value==undefined||value==null || value=="") {
          return callback()
        }
        if (!carNum.test(value)) {
          callback(new Error('请输入正确的车牌号'))
        } else {
          callback()
        }
      },
      // 身份证的验证规则
      ID (rule, value, callback) {
        if (value==""||value==undefined) {
          return callback();
        }
        // if (!regId.test(value)) {
        //   callback(new Error('请输入正确的二代身份证号码'))
        // } else {
        //   callback()
        // }
        function validataCredentials(num) {
          var msg, boo; //  msg身份证验证友情提示,boo返回值
          var num = num.toUpperCase(); // 身份证为末尾可能是X
          //   身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，
          //   最后一位是校验位，可能为数字或字符X。
          const arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
          const arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
          var nTemp = 0,
            i;
          if (!(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num))) {
            //  输入的身份证号长度不对，或者号码不符合规定;
            return [boo = false, msg = "输入的身份证号长度不对，或者号码不符合规定"];
          }
          //   校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
          //   下面分别分析出生日期和校验位
          var len, re;
          len = num.length;
          if (len == 15) {
            re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
            var arrSplit = num.match(re);
            //   检查生日日期是否正确
            var dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
            var bGoodDay;
            bGoodDay = (dtmBirth.getYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
            if (!bGoodDay) {
              //   输入的身份证号里出生日期不对！
              return [boo = false, msg = "输入的身份证号里出生日期不对！"];

            } else {
              //   将15位身份证转成18位
              //   校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
              num = num.substr(0, 6) + '19' + num.substr(6, num.length - 6);
              for (i = 0; i < 17; i++) {
                nTemp += num.substr(i, 1) * arrInt[i];
              }
              num += arrCh[nTemp % 11];
              return [boo = true, msg = num];
            }
          }
          if (len == 18) {
            re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
            var arrSplit = num.match(re);
            //  检查生日日期是否正确
            var dtmBirth = new Date(arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]);
            var bGoodDay;
            bGoodDay = (dtmBirth.getFullYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
            if (!bGoodDay) {
              //  输入的身份证号里出生日期不对！

              return [boo = false, msg = " 输入的身份证号里出生日期不对"];
            } else {
              //  检验18位身份证的校验码是否正确。
              //  校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
              var valnum;
              for (i = 0; i < 17; i++) {
                nTemp += num.substr(i, 1) * arrInt[i];
              }
              valnum = arrCh[nTemp % 11];
              if (valnum != num.substr(17, 1)) {
                //  18位身份证的校验码不正确！
                return [boo = false, msg = "身份证的校验码不正确！"];
              }
              return [boo = true, msg = "验证成功"]
            }
          }
          return [boo = false, msg = "身份证的长度不正确！"];
        }
        setTimeout(() => {
          var res = validataCredentials(value);
        if (!res[0]) {
          callback(new Error(res[1]));
        } else {
          callback();
        }
      }, 200);


      }
    }
  }

  return FormValidate
}())

exports.FormValidate = FormValidate
