import { timestamp } from  '@/utils/timestamp';
export default {
  //组件
  components: {
    expandDom: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null
        }
      },
      render: (h, ctx) => {
        const params = {
          row: ctx.props.row,
          index: ctx.props.index
        };
        if (ctx.props.column) params.column = ctx.props.column;
        return ctx.props.render(h, params);
      }
    }
  },
  props: {
    tblRef: {
      type: String,
      default: "mytable"
    },
    cellStyle: {
      type: Function
    },
    border: {
      type: Boolean,
      default: false
    },
    rowDblclick: {
      // 单行双击事件
      type: Function,
      default: (row, event, column) => {
        console.log("default: " + row + "---" + event + "---" + column);
      }
    },
    operateWidth: {
      type: String,
    },
    tabheight: {
      type: String,
      default: "100%"
    },
    isLoad: {
      // 是否需要加载等待
      type: Boolean,
      default: true
    },
    isshow: {
      type: Boolean,
      default: false
    },
    showOpe: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: []
    },
    operates: {},
    currentPage: {
      default() {
        return 1;
      },
      type: Number
    },
    pageSizes: {
      default() {
        return [30, 50, 100];
      },
      type: Array
    },
    pageSize: {
      default() {
        return 30;
      },
      type: Number
    },
    layout: {
      default() {
        return "total, sizes, prev, pager, next, jumper";
      },
      type: String
    },
    checkMultiple: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ""
    },
    param: {
      type: Object,
      default() {
        return {};
      }
    },
    urlHearder: {
      type: String,
      // default: "application/x-www-form-urlencoded;charset=UTF8"
      default: 'application/json; charset=UTF-8'
    },
    pagination: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tableData: [],
      total: 0,
      currentRow: null,
      loading: false,
      page: {},
      dialogVisible: false,
      dialogImageUrl: '',
      swiperImgList: []
    };
  },
  // watch:{
  //   param:{
  //     handler(n,o){
  //       console.log(n,'new')
  //     },
  //     deep:true,
  //   }
  // },
  created() {
    // this.size = this.pageSize;
    // this.no = this.currentPage;
  },
  methods: {
    dialogImageUrlFn(pic) {
      this.dialogVisible = true;
      this.dialogImageUrl = pic;
    },
    clickRow(row) {/*行单击事件*/
      if (this.checkMultiple != true) {
        this.$refs[this.tblRef].clearSelection();
      }
      this.$refs[this.tblRef].toggleRowSelection(row)
    },
    // 点击分页
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.$emit("update:pageSize", val);

      let size = 1;
      let toSize = Math.ceil(this.total / val);
      if (this.param.pageNo <= toSize) {
        size = this.param.pageNo
      } else {
        size = toSize;
      }
      this.page.pageNo = size;
      this.$emit("update:currentPage", size);
      this.$emit("current-change", size);
      this.initTableData(this.page);
    },
    currentChange(val) {
      this.currentRow = val;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.page.pageSize = this.param.pageSize;
      this.initTableData(this.page);
      this.$emit("update:currentPage", val);
      this.$emit("current-change", val);
    },
    selectionChange(data) {
      this.$emit("selection-change", data);
    },
    initTableData(page, callback) {
      this.$refs[this.tblRef].doLayout();
      if (this.isLoad) {
        this.loading = true;
      }
      if (page && page.hasOwnProperty("pageNo")) {
        this.param.pageNo = page.pageNo;
      } else {
        // this.param.pageNo = this.currentPage;
      }
      if (page && page.hasOwnProperty("pageSize")) {
        this.param.pageSize = page.pageSize;
      } else {
        // this.param.pageSize = this.pageSize;
      }
      var this_ = this;
      this.http
        .ajaxHandler()
        .success(function (code, msg, data) {
          if (code == 0) {
            if (this_.pagination) {
              if (data != null && data.list == null) {
                data.list = [];
              } else if (data == null) {
                this_.loading = false;
                return;
              }
              this_.tableData = data.list;
              this_.total = data.total;
              this_.tableData.forEach(item => {
                if(typeof item.createTime == 'number'){
                  item.createTime = timestamp(item.createTime)
                }
                let oderStatus = '';
                if (!!item.orderStatus) {
                  oderStatus = item.orderStatus
                } else if (!!item.oderStatus) {
                  oderStatus = item.oderStatus
                }
                // 订单状态 分佣订单状态
                if (oderStatus == 0) {
                  item.oderStatusName = '已失效'
                } else if (oderStatus == 1) {
                  item.oderStatusName = '进行中'
                } else if (oderStatus == 2) {
                  item.oderStatusName = '订单异常'
                } else if (oderStatus == 3) {
                  item.oderStatusName = '已退款'
                } else if (oderStatus == 4) {
                  item.oderStatusName = '已完结'
                } else if (oderStatus == 5) {
                  item.oderStatusName = '等待付款成功回执中'
                }
                if(item.userStatus == 0){
                  item.userStatusName='不可用'
                }else if(item.userStatus == 1){
                  item.userStatusName='可用'
                }

                // 是否支付状态翻译
                if (item.merchantId && item.status == 0) {
                  item.payStatus = '未支付'
                } else if (item.merchantId && item.status == 1) {
                  item.payStatus = '已支付'
                } else if (item.merchantId && item.status == 2) {
                  item.payStatus = '已退款'
                } else if (item.merchantId && item.status == 4) {
                  item.payStatus = '已取消'
                }
                // 是否退款
                if (item.direction == 0) {
                  item.directionName = '已付款'
                } else if (item.direction == 1) {
                  item.directionName = '已退款'
                }
                // 是否审批通过 （下级代理商）
                if (item.userStatus == 1) {
                  item.userStatusName = '可用'
                } else {
                  item.userStatusName = '不可用'
                }

                if (item.templateType == 0) {
                  item.templateTypeName = '模型模版'
                } else {
                  item.templateTypeName = '图像模版'
                }
                
                if(item.appUserInfo && item.appUserInfo.userName){
                  item.userName = item.appUserInfo.userName 
                }
                
                if(item.appUserInfo && item.appUserInfo.id){
                  item.userId = item.appUserInfo.id 
                }

                if(item.appUserInfo && item.appUserInfo.avatar){
                  item.imgList = ['https://aiphoto.studyai.fun/' + item.appUserInfo.avatar ]
                }
                // if(item.photosList){
                //   item.photosList.forEach(img => {
                //     if(img.status == 2 && item.finishTime && item.oderStatus == 4){
                //       item.oderStatusName = '已完结(已打印)';
                //     }else if(img.status == 2 && item.oderStatus == 4){
                //       item.oderStatusName = '已完结(未打印)';
                //     }else if(item.oderStatus == 4){
                //       item.oderStatusName = '已完结'
                //     }
                //   })
                // }
                // 分佣状态
                if (item.cashbackStatus == 1) {
                  item.cashbackStatusName = '已结清'
                } else if (item.cashbackStatus == 2) {
                  item.cashbackStatusName = '未结清'
                }
              })

            } else {
              if (data) {
                if (data == null) {
                  data = []
                }
                this_.tableData = data;
                this_.total = data.length;
                this_.tableData.forEach(item => {
                  if (item.oderStatus == 0) {
                    item.oderStatusName = '已失效'
                  } else if (item.oderStatus == 1) {
                    item.oderStatusName = '进行中'
                  } else if (item.oderStatus == 2) {
                    item.oderStatusName = '订单异常'
                  } else if (item.oderStatus == 3) {
                    item.oderStatusName = '已退款'
                  } else if (item.oderStatus == 4) {
                    item.oderStatusName = '已完结'
                  }

                  // if(item.photosList){
                  //   item.photosList.forEach(img => {
                  //     if(img.status == 2 && item.finishTime && item.oderStatus == 4){
                  //       item.oderStatusName = '已完结(已打印)';
                  //     }else if(img.status == 2 && item.oderStatus == 4){
                  //       item.oderStatusName = '已完结(未打印)';
                  //     }else if(item.oderStatus == 4){
                  //       item.oderStatusName = '已完结'
                  //     }
                  //   })
                  // }
                  if (item.userStatus == 1) {
                    item.userStatusName = '可用'
                  } else {
                    item.userStatusName = '不可用'
                  }
                })
              } else {
                this_.tableData = [];
                this_.total = 0;
              }
            }
            this_.$emit("getTotal", this_.total);
          } else {
            this_.tableData = [];
            this_.total = 0;
          }
          this_.page = {};
          if (callback) {
            callback();
          }
          this_.loading = false;
        })
        .error(function (code, msg) {
          this_.loading = false;
          this_.$message({
            message: msg,
            type: "warning"
          });
        })
        .post({
          headers: {
            "content-type": this.urlHearder
          },
          url: this.url,
          data: this.param
        });
    }
  }
};
