<template>
  <div>
    <el-form
      v-show="!forgetShow && !register"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="0px"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item prop="username">
            <el-input placeholder="请输入账号" v-model="ruleForm.username">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="position: relative">
          <el-form-item prop="password">
            <el-input
              :type="typepsd1"
              placeholder="请输入密码"
              v-model="ruleForm.password"
            >
            </el-input>
          </el-form-item>
          <!-- @click="handleclose" -->
          <img  class="typepsd_img" style="cursor: default;" :src="imgsrc" alt="" />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="forgetPass">
            <el-checkbox v-model="forgetChecked">记住密码</el-checkbox>
            <div class="forget" @click="handleForget">忘记密码</div>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="24">
          <el-button
            class="tabsPsd_btn"
            type="primary"
            @click="submitForm('ruleForm')"
            >登 录</el-button
          >
        </el-col>
      </el-row>
        <!-- 立即注册 -->
      <!-- <div class="regest">
        <span
          >还没有账号？<a class="target" @click="registerFn">立即注册</a></span
        >
      </div> -->
    </el-form>
    <!-- 忘记密码 start -->
    <el-form
      v-show="forgetShow"
      :model="forgotForm"
      :rules="forgotRules"
      ref="forgotForm"
      label-width="0px"
      class="demo-ruleForm"
    >
      <el-row>
        <!-- <el-col :span="24">
          <el-form-item prop="username">
            <el-input placeholder="请输入用户名" v-model="forgotForm.username">
            </el-input>
          </el-form-item>
        </el-col> -->
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="mobile">
            <el-input placeholder="请输入手机号" v-model="forgotForm.mobile">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16">
          <el-form-item prop="code">
            <el-input
              class="borderInput"
              placeholder="短信验证码"
              v-model="forgotForm.code"
              :disabled="!dis"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-button
            :class="{ greySm: isGrey, blue: !isGrey }"
            :disabled="dis"
            type="primary"
            style="border-radius: 0px 4px 4px 0"
            @click="forgetGetCode(13)"
          >
            <span v-if="show">发送验证码</span>
            <span v-else>重新发送({{ count }}s)</span>
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="position: relative">
          <el-form-item prop="newPassword">
            <el-input
              :type="typepsd4"
              placeholder="请输入新密码"
              v-model="forgotForm.newPassword"
            >
            </el-input>
          </el-form-item>
            <img @click="handleclose4" class="typepsd_img" :src="imgsrc4" alt="" />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="position: relative">
          <el-form-item prop="newCfPassword">
            <el-input
              :type="typepsd5"
              placeholder="请重复输入新密码"
              v-model="forgotForm.newCfPassword"
            >
            </el-input>
          </el-form-item>
           <img @click="handleclose5" class="typepsd_img" :src="imgsrc5" alt="" />
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="24">
          <el-button
            class="tabsPsd_btn"
            type="primary"
            :disabled="forgotDisabled"
            @click="forgotBtn('forgotForm')"
            >重置密码</el-button
          >
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="24">
          <el-button
            style="width: 100%; height: 50px"
             @click="goLogin"
            >返 回</el-button
          >
        </el-col>
      </el-row>
    </el-form>
    <!-- 立即注册 start -->
    <el-form
      v-show="register"
      :model="registerForm"
      :rules="registerRules"
      ref="registerForm"
      label-width="0px"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item prop="inviteCode">
            <el-input
              placeholder="邀请码"
              v-model="registerForm.inviteCode"
              disabled="true"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="username">
            <el-input
              placeholder="请输入用户名"
              v-model="registerForm.username"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="mobile">
            <el-input placeholder="请输入手机号" v-model="registerForm.mobile">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16">
          <el-form-item prop="code">
            <el-input
              class="borderInput"
              placeholder="短信验证码"
              v-model="registerForm.code"
              :disabled="!dis"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-button
            :class="{ greySm: isGrey, blue: !isGrey }"
            :disabled="dis"
            type="primary"
            style="border-radius: 0px 4px 4px 0"
            @click="getRegisterCode(11)"
          >
            <span v-if="show">发送验证码</span>
            <span v-else>重新发送({{ count }}s)</span>
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="position: relative">
          <el-form-item prop="password">
            <el-input
              :type="typepsd2"
              placeholder="请输入密码"
              v-model="registerForm.password"
            >
            </el-input>
          </el-form-item>
          <img @click="handleclose2" class="typepsd_img" :src="imgsrc2" alt="" />
        </el-col>
        <!-- <div class="schedule">
          <span :class="schedule >= 1?'green':'gray'"></span>
          <span :class="schedule >= 2?'green':'gray'"></span>
          <span :class="schedule >= 3?'green':'gray'"></span>
          <span :class="schedule >= 4?'green':'gray'"></span>
          <span :class="schedule >= 5?'green':'gray'"></span>
        </div> -->
      </el-row>
      <el-row>
        <el-col :span="24" style="position: relative">
          <el-form-item prop="confirmPassword">
            <el-input
              :type="typepsd3"
              placeholder="确认密码"
              v-model="registerForm.confirmPassword"
            >
            </el-input>
          </el-form-item>
           <img @click="handleclose3" class="typepsd_img" :src="imgsrc3" alt="" />
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="24">
          <el-button
            class="tabsPsd_btn"
            type="primary"
            :disabled="registerDisabled"
            @click="registerBtn"
            >注 册</el-button
          >
        </el-col>
      </el-row>
      <!-- <el-row style="margin-top: 10px">
        <el-col :span="24">
          <el-button style="width: 100%; height: 50px" @click="goLogin"
            >返 回</el-button
          >
        </el-col>
      </el-row> -->
    </el-form>
    <Verify
      @success="registerSuccess"
      :mode="'pop'"
      :captchaType="'blockPuzzle'"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verifyRegister"
    ></Verify>
    <Verify
      @success="success"
      :mode="'pop'"
      :captchaType="'blockPuzzle'"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    ></Verify>
  </div>
</template>

<script>
import elTabsPassword from "./js/elTabsPassword";
export default {
  ...elTabsPassword,
};
</script>
<style lang='less' scoped>
@import "./css/elTabsPassword.less";
</style>
