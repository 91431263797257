<template>
  <div class="box" style="height: 100%;">
    <div ref="vantaRef" style="width: 100%; height: 100%"></div>
    <div class="login_bck">
      <div style="position: fixed;top: 60px;left: 50%;transform: translateX(-50%);font-size: 30px; color: #fff;">数 字 摄 影 商 家 管 理 系 统</div>
      <div :class="label_n == '注册' ? 'login_contentRE' : 'login_content'">
        <!-- <div class="title_1">
          <img width="400px" height="50px" src="@/assets/images/logoname.jpg" alt="">
        </div> -->
      <el-tabs v-model="activeName">
        <el-tab-pane :label="label_n" name="first" >

            <tabsPsd ref="tabsPsd" @registerTit="registerTit"/>
          </el-tab-pane>
          <!--<el-tab-pane label="手机" name="second">
              <tabsPhone ref="tabsPhone" />
            </el-tab-pane>-->
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import login from './js/login'

export default {
  ...login
}
</script>
<style lang='less' scoped>
@import "./css/login.less";

.box {
  position: relative;
  .banner {
    z-index: 999;
    position: absolute;
    top: 30%;
    left: 10%;
    color: #fff;
  }
}
.login_bck{
  position: absolute;
  top: 0%;
  // z-index: 9999;
}
h1 {
  font-size: 66px;
}

p {
  margin-top: 60px;
  font-size: 18px;
}
</style>
