
export default {
  components: {},
  data() {
    return {
      phoneForm: {
        phoneNumber: '',
        Pnumber: '',
      },
      phonerules: {
        phoneNumber: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 11 个字符', trigger: 'blur' }
        ],

        Pnumber: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在4个字符', trigger: 'blur' }
        ],
      }
    };
  },
  computed: {},
  watch: {},
  created() {

  },
  methods: {

  },
}
